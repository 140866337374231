@import "./colors";

.fullpage-loader {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    color: $primary;
    svg {
      height: 48px;
      width: 48px;
    }
  }
}

.text-primary {
  color: $primary;
}

.bg-grey {
  background-color: $grey;
}

.h-screen {
  min-height: 100vh;
}

.mb {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 4rem;
}
