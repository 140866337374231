@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './antd.scss';
@import './utils.scss';

html,
body {
  padding: 0;
  margin: 0;
}

body[dir='ltr'] #english {
    display: none;
    color: inherit;
}

body[dir='rtl'] #arabic {
    display: none;
}

body[dir='rtl'] #english {
    display: block;
    color: inherit;
}

body[dir='ltr'] #arabic {
    display: block;
}

body[dir='rtl'] .ant-form-item-label > label {
    float: right;
}

#aaaa span {
    display: inline-block;
    width: 100%;
    margin-bottom: 3px;
    margin-top: 10px;
}

ul.list-disc {
    list-style-type: disc;
    color: #fff;
    padding-right: 20px;
}

* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-size: 16px;
}

body > iframe {
  pointer-events: none;
}

h1 {
  font-size: 32px;
  font-weight: 600;
}

h3 {
  font-size: 26px;
  font-weight: 500;
}

h4 {
  font-size: 22px;
  font-weight: 500;
}
 
h5 {
  font-size: 20px;
  font-weight: 500;
}

.container-fluid {
  width: 100%;
  height: fit-content;
  padding: 0 4rem;

  @media screen and (max-width: 992px) {
    padding: 0 3rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 576px) {
    padding: 0 1rem;
  }
}

.container {
  width: 100%;
  max-width: 1336px;
  height: fit-content;
  padding: 0 1rem;
  margin: 0 auto;
}

.logo {
  height: 5vh;
  width: auto;
  &:hover {
    cursor: pointer;
  }
}

.header-offset {
  margin-top: 90px;
}

.img-fluid {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

section {
  padding: 60px 0;
}

.btn {
  height: fit-content;
  width: fit-content;
}

.btn-primary {
  background-color: $primary;
  border: none;
  outline: none;
  padding: 0.75rem 2rem;
  font-size: 18px;
  color: white;
  text-align: center;
  width: 100%;
  margin-top: 1rem;
  transition: all 0.6s ease-in-out;

  &:hover,
  &:focus {
    background-color: $text;
    color: white;
    cursor: pointer;
  }
}

.dark-input {
  .ant-form-item-label {
    margin-left: 0.5rem;
    color: $text;
  }

  input,
  textarea {
    height: fit-content;
    width: 100%;
    padding: 0.6rem 0.5rem;
    border-radius: 2px;
    background-color: $lightGray;
    border-color: $lightGray;
    color: $text;
    &::placeholder {
      color: rgba($text, 0.6);
    }
  }
}

.privacy {
  padding-top: 2em;
  padding-bottom: 2em;
  h1 {
    font-size: 32px;
    font-weight: 700;
    margin: 1.5em 0;
  }
  h2 {
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 0.5em;
  }
  h3 {
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 0.5em;
  }
  h4 {
    font-size: 14px;
    font-weight: 700;
    color: $textLight;
    padding-bottom: 0.5em;
  }
  p {
    font-size: 14px;
    margin-bottom: 1em;
    a {
      font-size: 14px;
      color: blue;
    }
  }
  ul {
    margin-left: 2em;
    margin-bottom: 1em;
    list-style: disc;
    li {
      font-size: 14px;
    }
  }
}
.Free {
    width: 74px;
    height: 70px;
    position: absolute;
    top: 19px;
    left: 50%;
    transform: translate(-50%,0);
}
.tracking-tighter {
	font-size:3rem;
}
a.bg-white.font-bold.rounded-full.py-4.px-8.shadow-lg.uppercase.tracking-wider {
    background: #f58c13;
    color: #fff;
	position:relative;
}

.tracking-wider .Free{
	top:unset;
}
.rating .ant-rate .ant-rate-star.ant-rate-star-full {margin:0;}
