@import './colors';

html {
  --antd-wave-shadow-color: none;
}

.sidebarOverlay {
  .ant-drawer-content-wrapper {
    width: 40% !important;
    @media screen and (max-width: 992px) {
      width: 100% !important;
    }
  }
}

.searchOverlay {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    height: 100% !important;
    @media screen and (max-width: 992px) {
      width: 100% !important;
    }
    .ant-drawer-close {
      font-size: 20px;
      color: #111;
    }
  }
}

.filterOverlay {
  .ant-drawer-content-wrapper {
    width: 40% !important;
    @media screen and (max-width: 992px) {
      width: 100% !important;
    }
  }
}

///--------------------------------------------------------------------------

///                               Ant Input

///---------------------------------------------------------------------------
.large-input.ant-input-affix-wrapper {
  padding: 1rem;
  &:hover {
    border-color: $primary !important;
  }
  &:focus {
    border-color: $primary !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-input {
    &::placeholder {
      font-weight: 400 !important;
      color: rgba($text, 0.5) !important;
    }
  }
}

.ant-select {
  width: 100%;

  .ant-select-selector {
    padding: 0.75rem !important;
    height: 100% !important;
    &:hover {
      border-color: $primary !important;
    }
  }

  .ant-select-selection-placeholder {
    font-weight: 400;
    color: rgba($text, 0.5);
  }

  .ant-select-arrow {
    font-weight: 400 !important;
    color: rgba($text, 0.5) !important;
  }
}

.searchBar {
  width: 80%;

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $primary !important;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: $primary !important;
    box-shadow: 0 0 0 2px rgba($primary, 0.2) !important;
  }

  .ant-input-group-addon > button {
    background-color: $primary;
    border-color: $primary;

    &:hover,
    &:focus {
      background-color: $primary;
      border-color: $primary;
    }
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

///--------------------------------------------------------------------------

///                        ANT MENY & RADIO GROUP

///---------------------------------------------------------------------------

.ant-menu .ant-menu-root,
.ant-menu-inline,
.ant-menu-light {
  border: none !important;
}

.ant-menu-submenu,
.ant-menu-submenu-inline {
  border-bottom: 1px solid $darkGray !important;
}

.ant-menu-submenu,
.ant-menu-submenu-inline,
.ant-menu-submenu-open,
.ant-menu,
.ant-menu-sub,
.ant-menu-inline,
.ant-radio-group,
.ant-radio-group-outline {
  background-color: white !important;
}

.listMenu {
  .ant-menu-submenu,
  .ant-menu-submenu-inline {
    border: none !important;
    .ant-menu-submenu-title {
      margin: 0;
      padding: 0 !important;
      line-height: 1;
      transition: all 0.5s ease-in-out;
      &:hover {
        color: $primary;
      }
    }
  }
}

.ant-menu-item,
.ant-menu-item-active,
.ant-menu-item-selected,
.ant-menu-item-only-child {
  background-color: white !important;
  &::after {
    display: none !important;
  }
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: fit-content;
}

.ant-menu .ant-menu-submenu-title {
  color: text;
  font-size: 16px;
  font-weight: 500;

  .ant-menu-submenu-arrow {
    color: text !important;
  }
  &:hover {
    color: text;
  }
}

.ant-menu-title-content {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 1rem;
  }
}

.ant-menu-submenu-active {
  .ant-menu-title-content {
    color: $primary;
  }
}

.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: $primary;
}

.ant-radio .ant-radio-inner {
  background-color: white;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid $text !important;
}

.ant-radio-wrapper {
  font-size: 14px !important;
  color: $text;
}

.ant-radio-checked .ant-radio-inner {
  background-color: text;
  border-radius: 5px;
  box-shadow: none !important;
  border: 1px solid text !important;
  &::after {
    background-color: white;
  }
}

///--------------------------------------------------------------------------

///                        Slider & Carosel and RATE

///---------------------------------------------------------------------------

.select {
  width: 100% !important;
  .ant-select-selector {
    padding: 8px 16px !important;
    height: fit-content !important;
  }
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 2px solid $primary !important;
}

.image-gallery-image {
  width: 100% !important;
  height: 500px;
}

.slick-prev,
.slick-next {
  height: 40px;
  width: 40px;
}

.sliderArrows {
  font-size: 40px !important;
  height: 40px !important;
}

.slick-track {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.ant-collapse-item {
  padding: 0.5em;
}

@media screen and (max-width: 992px) {
  .styles_lgImage__3T6UC div {
    img {
      width: 350px !important;
      height: 350px !important;
    }
  }

  .ant-tabs-content-holder {
    text-align: center !important;
  }
}

.rating {
  .ant-rate {
    margin: 0;
    padding: 0;
    line-height: 1;
    .ant-rate-star.ant-rate-star-full {
      color: $primary !important;
    }
  }
}

///--------------------------------------------------------------------------

///                                   TABS

///---------------------------------------------------------------------------
.ant-tabs-tab {
  .ant-tabs-tab-btn {
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $text;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary;
}

.ant-tabs-ink-bar {
  background: $primary;
}

.ant-tabs-content-holder {
  @media screen and (max-width: 992px) {
    text-align: left !important;
  }
}
